<script setup lang="ts">
//TODO: add correct type
const props = defineProps<{
  content: any;
}>();

const { getSlotContent } = useCmsBlock(props.content);
const slotContent = getSlotContent('grid');
</script>

<template>
  <div>
    <div class="cms-block-highlight-slider__wrapper">
      <CmsGenericElement
        v-if="slotContent"
        :content="slotContent"
        class="cms-block-highlight-slider"
      />
    </div>
  </div>
</template>
